import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from '../../../components/layout/CustomerFooter';
import ModalCheckIcon from '../../../assets/Svg/ModalCheckIcon';
import BackButton from '../../../components/Reusable/BackButton';
import SuccessFullPaymentModal from '../../../components/Order/SuccessFullPaymentModal';
import OrderPlacedModal from '../../../components/Order/OrderPlacedModal';
import OtherService from '../../../services/OtherService';
import CCAvenueService from '../../../services/CCAvenue.service';
import $ from 'jquery'
import OrderService from '../../../services/Order.service';
import Common from '../../../hoc/Common.hoc';
import OrderAction from '../../../redux/actions/Order.action';
import config from '../../../config/emrok.config';
class OrderPlaced extends Component {
    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.state = {
            OrderReducer: null,
            merchantId: config.ccavenueMerchantID,
            // accessCode: 'AVFW05KH13BV88WFVB',
            accessCode: config.ccavenueAccessCode,
            // workingKey: '165C6707ED3E0039CD717A2B1E78DA35',
            workingKey: config.ccavenueWorkingKey,
            order_id: 'ORDER123',
            amount: '1.00',
            redirectUrl: config.ccavenueRedirectURL,
            checksum: "",
            paymentDetails: null,
            isCod: true,
            paymentType: "cod",
            refundDetails: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            OrderReducer: props.OrderReducer || null,
            paymentDetails: props.OrderReducer ? props.OrderReducer.paymentDetails ? props.OrderReducer.paymentDetails : null : null
        }
    }

    getTransaction = () => {
        console.log(this.props, "akhsdghasgjk")
        let order_id = this.props.OrderReducer ? this.props.OrderReducer.currentOrder ? this.props.OrderReducer.currentOrder.order_id ? this.props.OrderReducer.currentOrder.order_id : null : null : null
        console.log(order_id, "ajks")
        if (order_id) {
            OrderService.getTransaction({ order_id }).then(async data => {
                let found = data.data.data.data.found || null
                console.log(data.data.data.data, "getTransaction", data.data.data.data.found, found)
                let successMessage = data.data.data.data.message || null
                if (successMessage && found) {

                    console.log("if onlinepayment")

                    // alert("if onlinepayment");
                    // alert(successMessage)
                    // alert(found)
                    // OtherService.getCustomerOut().then(res => {
                    //     // console.log(res, 7878);
                    //     if (res && res.data && res.data.success) {
                    //         console.log(res, 123456);
                    //     }

                    // }).catch(err => {
                    //     console.log(err)
                    // })
                    const { mobileno } = this.props.CustomerReducer.customer;
                    let orderData = {
                        mobileno: mobileno,
                        order_id: this.state.OrderReducer ? this.state.OrderReducer.currentOrder ? this.state.OrderReducer.currentOrder.order_id ? this.state.OrderReducer.currentOrder.order_id : null : null : null,
                        _id: this.state.OrderReducer ? this.state.OrderReducer.currentOrder ? this.state.OrderReducer.currentOrder._id ? this.state.OrderReducer.currentOrder._id : null : null : null,
                        customer_id: this.props.CustomerReducer.customer._id,
                        customername: this.props.CustomerReducer.customer.name,
                        mrname: this.state.OrderReducer.currentOrder.mr[0].name,
                        mr_email: this.state.OrderReducer.currentOrder.mr[0].email,
                        doctorname: this.state.OrderReducer.currentOrder.doctor.name,
                        stockist_email: this.state.OrderReducer.currentOrder.stockist.email
                    };






                    this.props.hoc.customAlert(successMessage == "Success" ? "Payment Success" : "Payment Failed", successMessage == "Success" ? true : false, null, false, false, () => {
                        if (successMessage == "Success") {

                            OtherService.generateMsg(orderData).then(res => {

                                console.log(res)
                            }).catch(err => {
                                console.log(err)
                            });

                            this.props.navigate("/customer/order-information")
                        }
                    })
                }
            }).catch(err => {
                console.log(err, "getTransaction error")
            })
        }
    }

    componentDidMount = () => {
        // window.history.pushState(null, document.title, window.location.href);
        // window.addEventListener('popstate', function (event) {
        //     window.history.pushState(null, document.title, window.location.href);
        // });
        const orderDetails = this.state.OrderReducer.currentOrder;
        console.log(orderDetails, "orderDetails");

        this.getTransaction()
    }

    sendMsgApiCall = (e) => {

        e.preventDefault();
        const { currentOrder } = this.props.OrderReducer;
        if (currentOrder.payment_type == "cod" || currentOrder.payment_type == null) {
            currentOrder.payment_type = "cod";
            console.log(currentOrder, "currentOrder");
            this.props.placeOrder(currentOrder);
        }



        const { mobileno } = this.props.CustomerReducer.customer;
        const data = { mobileno };
        console.log(data)
        console.log(this.props.CustomerReducer.customer.mobileno, 'fgfgg');

        let details = {
            order_id: this.state.OrderReducer ? this.state.OrderReducer.currentOrder ? this.state.OrderReducer.currentOrder.order_id ? this.state.OrderReducer.currentOrder.order_id : null : null : null,
            payment_type: "online"
        }

        let orderData = {
            mobileno: mobileno,
            order_id: this.state.OrderReducer ? this.state.OrderReducer.currentOrder ? this.state.OrderReducer.currentOrder.order_id ? this.state.OrderReducer.currentOrder.order_id : null : null : null,
            _id: this.state.OrderReducer ? this.state.OrderReducer.currentOrder ? this.state.OrderReducer.currentOrder._id ? this.state.OrderReducer.currentOrder._id : null : null : null,
            customer_id: this.props.CustomerReducer.customer._id,
            customername: this.props.CustomerReducer.customer.name,
            mrname: "",
            doctorname: this.state.OrderReducer.currentOrder.doctor.name,
            stockist_email: this.state.OrderReducer.currentOrder.stockist.email,
            mr_email: "",
        };



        OtherService.updatePaymentType(details).then(res => {

            console.log(res)
        }).catch(err => {
            console.log(err)
        });


        let products = this.state.OrderReducer ? this.state.OrderReducer.currentOrder ? this.state.OrderReducer.currentOrder.products ? this.state.OrderReducer.currentOrder.products : [] : [] : []
        let amount = 0
        for (let i = 0; i < products.length; i++) {
            let product = products[i]
            amount += ((+product.price) * (+product.quantity))
        }

        amount = this.state.OrderReducer.currentOrder.total;

        console.log(amount, "amount");
        const payload = {
            order_id: this.state.OrderReducer ? this.state.OrderReducer.currentOrder ? this.state.OrderReducer.currentOrder.order_id ? this.state.OrderReducer.currentOrder.order_id : null : null : null,
            // amount: amount ? amount.toFixed(2) : 0
            amount: amount ? parseFloat(amount).toFixed(2) : 0
        }
        console.log(data, this.state.OrderReducer, payload, 989898, this.state.isCod);
        if (!this.state.isCod) {
            CCAvenueService.generateChecksum(payload).then(data => {
                console.log(data, "sdfsd");
                if (data.success) {
                    setTimeout(() => {
                        $("#nonseamless").submit()
                    }, 2000);
                }
            })
        }

        if (this.state.isCod) {
            console.log("if cod")

            OtherService.generateMsg(orderData).then(res => {

                console.log(res)
            }).catch(err => {
                console.log(err)
            });


            OtherService.getCustomerOut().then(res => {
                // console.log(res, 7878);

                if (res && res.data && res.data.success) {
                    console.log(res, 123456);
                }

            }).catch(err => {
                console.log(err)
            })

        }


    }

    handelModel = () => {
        const modal = new window.bootstrap.Modal(document.getElementById('orderPlaced'));
        modal.hide();

        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.remove();
        }

        const body = document.body;

        // Modify the style
        body.style.overflow = 'auto';
        this.props.navigate('/customer/order-information');

    }

    handleRadioChange = (e) => {
        const { currentOrder } = this.props.OrderReducer;
        currentOrder.payment_type = e.target.id;
        console.log(currentOrder, "currentOrder");
        this.props.placeOrder(currentOrder);
        console.log(e.target.id, "ajkshdjkashk");
        const isCod = e.target.id === "cod"; // Check if the selected radio's id is "cod"
        this.setState({ isCod });
        this.setState({ paymentType: e.target.id });





        console.log(this.state.isCod, "isCod")
    }

    // paymentRefund = (e, payload = null) => {
    //     e.preventDefault()
    //     if (!payload) {
    //         payload = {
    //             order_id: "2024000082",
    //             amount: "32800",
    //             refund_reason: "Defective Product"
    //         }
    //     }
    //     CCAvenueService.paymentRefund(payload).then(async data => {
    //         console.log(data.data, "sdfsd");
    //         if (data.success) {
    //             this.setState({ refundDetails: data.data }, () => {
    //                 setTimeout(() => {
    //                     $("#nonseamless-refund").submit()
    //                 }, 2000);
    //             })
    //         }
    //     })
    // }

    render = () => {
        const { currentOrder } = this.props.OrderReducer;
        const { merchantId, accessCode, workingKey, order_id, amount, redirectUrl, checksum, paymentDetails, refundDetails } = this.state;

        console.log(this.state, "checksum");
        return (
            <>
                <section className="checkout-cart-content">
                    <div className="container">
                        <CustomerHeader />
                        {/* Row End*/}
                        <div className="row">
                            <div className="page-title-wrap mt-4">
                                <h1 className="page-title">Payment method</h1>
                            </div>
                        </div>
                        {/* Row End*/}
                        <div className="radio-btn-group">
                            <div className="row align-items-center row-cols-2 row-cols-3">

                                <div className="col">
                                    <div className="radio-content">
                                        <input
                                            type="radio"
                                            id="onlinePayment" // Use a unique id
                                            className="form-control radio-input"
                                            name="radio-group"
                                            checked={!this.state.isCod} // Check based on the state
                                            onChange={this.handleRadioChange}
                                        />
                                        <label htmlFor="onlinePayment" className="radio-label">
                                            Online Payment
                                        </label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="radio-content">
                                        <input
                                            type="radio"
                                            id="cod"
                                            className="form-control radio-input"
                                            name="radio-group"
                                            checked={this.state.isCod} // Check based on the state
                                            onChange={this.handleRadioChange}
                                        />
                                        <label htmlFor="cod" className="radio-label">
                                            Cod
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Radio btn grou end*/}
                        <div className="row">

                            <div className="d-flex justify-content-end">
                                <BackButton className="btn payment-dtls-submit px-4" />

                                <button
                                    className="btn payment-dtls-confirm px-4"
                                    data-bs-target={this.state.isCod ? "#orderPlaced" : null}
                                    data-bs-toggle={this.state.isCod ? "modal" : null}
                                    onClick={this.sendMsgApiCall}
                                >
                                    Confirm
                                </button>
                                <button
                                    type="submit"
                                    className="btn payment-dtls-confirm px-4"
                                    onClick={this.paymentRefund}
                                >
                                    Refund
                                </button>
                            </div>

                        </div>
                        {/* Row End*/}
                        {/* Row End*/}

                        <CustomerFooter />
                        {" "}
                        {/* Footer end*/}
                    </div>
                    <div>
                        {
                            paymentDetails && paymentDetails.dataObj &&
                            <form id="nonseamless" method="post" name="redirect" action={config.ccavenueRedirectURL}>
                                <input type="hidden" id="encRequest" name="encRequest" value={paymentDetails.dataObj.encRequest} />
                                <input type="hidden" name="access_code" id="access_code" value={paymentDetails.dataObj.access_code} />
                                <script language="javascript">document.redirect.submit();</script>
                            </form>
                        }
                        {
                            refundDetails &&
                            <form id="nonseamless-refund" method="post" name="redirect" action={config.ccavenueRefundRedirectURL}>
                                <input type="hidden" id="encRequest" name="encRequest" value={refundDetails.refundData.checksum} />
                                <input type="hidden" name="access_code" id="access_code" value={refundDetails.refundData.access_code} />
                                <script language="javascript">document.redirect.submit();</script>
                            </form>
                        }
                    </div>

                </section>
                {
                    this.state.isCod &&
                    <OrderPlacedModal currentOrder={currentOrder} navigateUrl={"/customer/order-information"} />
                }
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        placeOrder: (order) => dispatch(OrderAction.placeOrder(order))
    }
}

const mapStateToProps = (state) => {
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        OrderReducer: state.OrderReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(OrderPlaced)))
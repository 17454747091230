import React, { Component, useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import LocationIcon from '../../../assets/Svg/LocationIcon';
import CustomerService from '../../../services/Customer.service';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader"
import CustomerFooter from "../../../components/layout/CustomerFooter";
import BackButton from '../../../components/Reusable/BackButton';
import config from '../../../config/emrok.config';
import Common from '../../../hoc/Common.hoc';
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import OtherService from '../../../services/OtherService';
import CustomLoaderComponent from '../../../components/CustomLoader/CustomLoader.component';
import { Link } from "react-router-dom";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { Document, Page, pdfjs } from 'react-pdf';
// const defaultLayoutPluginInstance = defaultLayoutPlugin();
const PdfViewerWithPlugin = ({ pdfFile }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
    );
};
class Registration extends Component {

    constructor(props) {
        super(props)

        this.state = {
            registration: this.customerRegModel(),
            doctorName: "",
            selectedFile: null,
            fileUploadError: '',
            imagename: "",
            color: 'red',
            age: null,
            isValidAge: false,
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            prescription: null,
            isLoading: false,
            isChecked: false,
            selectedFile: [],
            image: null,
            isRegistered: false,
            numPages: null,
        }
        this.fileInputRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        //console.log(props, "kjdshkjshkd");
        return {
            prescription: props.CustomerReducer.prescription || null
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ selectedFile: file, fileUploadError: '', imagename: "" }, () => {
                this.handleUpload()
            });
        }
    };

    handleButtonClick = () => {

        // Programmatically trigger the file input click event
        this.fileInputRef.current.click();
    };

    handleUpload = async () => {
        console.log(this.state.isLoading, "loading status");

        const MaxFileSize = 10 * 1024 * 1024; // 10MB in bytes
        // Handle the file upload logic here
        const { selectedFile } = this.state;

        console.log(selectedFile);

        if (selectedFile) {
            this.setState({ isLoading: true });
            const fileType = selectedFile.type;
            const fileSizeMB = selectedFile.size / (1024 * 1024);
            if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'application/pdf') {

                this.setState({ isLoading: false });
                this.setState({ fileUploadError: 'Only JPG, PNG, and PDF files are allowed.', color: "red" });

                return;
            }
            else if (fileSizeMB > 25) {
                this.setState({ isLoading: false });
                this.setState({ fileUploadError: 'File size must be less than 25MB.', color: "red" });

                return;
            }
            else {
                const reader = new FileReader()
                // reader.onloadend = () => {
                //     console.log(reader, 7777)
                //     this.setState({ image: reader.result })
                // }
                reader.onload = (e) => {
                    this.setState({ image: e.target.result });
                };
                reader.readAsDataURL(selectedFile)
                // let newFile = await new File([file], this.state.product._id + '.' + file.name.split('.')[file.name.split('.').length - 1], { type: file.type })
                // this.setState({ selectedFile: newFile, fileUploadError: '', imagename: '' }, () => {
                //     // this.handleUpload()
                // });

                let payload = new FormData()
                // payload.append("customer_id", this.props.CustomerReducer.customer._id || null)
                // payload.append("doctor_id", this.props.CustomerReducer.customer.prescribedDoctor._id || null)
                payload.append("prescription", selectedFile)

                // console.log(selectedFile, 66666);

                this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: selectedFile.name, selectedFile: selectedFile });
                //console.log('File to upload:', selectedFile, this.props.CustomerReducer.customer, payload);
                // CustomerService.uploadPrescription(payload).then(data => {
                //     console.log(data.data.data.filepath, "data !!!!!!!!!!!!file pathh");
                //     this.getPrescription()
                //     this.setState({ isLoading: false });
                //     this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: data.data.data.filepath });
                // }).catch(err => {
                //     this.setState({ isLoading: false });
                //     console.log(err, "err")
                // })
            }
        } else {
            this.setState({ fileUploadError: 'Please select a file first.' });
        }
    };

    customerRegModel = (data = { address: {} }) => {
        if (data.address == null) {
            data.address = {};
        }
        const obj = {
            name: data.name ? data.name : "",
            address: {
                _id: data.address ? data.address._id : null,
                country: data.address.country ? data.address.country : "",
                addressLine1: data.address.addressLine1 ? data.address.addressLine1 : "",
                addressLine2: data.address.addressLine2 ? data.address.addressLine2 : "",
                town: data.address.town ? data.address.town : "",
                state: data.address.state ? data.address.state : "",
                pin: data.address.pin ? data.address.pin : "",
            },
            age: data.age ? data.age : "",
            email: data.email ? data.email : "",
            mobileno: data.mobileno ? data.mobileno : "",
            gender: data.gender ? data.gender : "",
            patientAddressLine1: data.address.patientAddressLine1 ? data.address.patientAddressLine1 : "",
            patientAddressLine2: data.address.patientAddressLine2 ? data.address.patientAddressLine2 : "",
            patientCountry: data.address.patientCountry ? data.address.patientCountry : "",
            patientState: data.address.patientState ? data.address.patientState : "",
            patientTown: data.address.patientTown ? data.address.patientTown : "",
            patientPin: data.address.patientPin ? data.address.patientPin : "",
            drName: data.drName ? data.drName : "",
            drRegistrationNo: data.drRegistrationNo ? data.drRegistrationNo : "",
            drPin: data.drPin ? data.drPin : "",
            consent: data.consent ? data.consent : false,
            prescription: data.prescription
        }
        return { ...obj }
    }

    componentDidMount() {
        CountryService.getCountries().then(data => {
            //console.log(data, "countries")
            if (data.success) {
                this.setState({ countries: data.countries })
            }
        })
        const customer = this.props.CustomerReducer.customer;
        console.log(customer, 5555);
        //console.log(this.customerRegModel(customer), customer, 89347298379823474)
        console.log(this.state.prescription, "popopopopo")
        if (this.state.prescription) this.getPrescription()

        this.setState({
            registration: customer ? this.customerRegModel(customer) : this.customerRegModel(),
            doctorName: customer.prescribedDoctor ? customer.prescribedDoctor.name : ""
        }, async () => {
            let registration = await this.state.registration
            registration["address"]["country"] = "India"
            await this.setState({ registration })
            // let country = await this.state.registration.address.country || null
            // console.log(country);
            // if (country) {
            // let countryObj = this.state.countries.find(i => i.name === "India")
            // alert(JSON.stringify(countryObj))
            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)
            }, 500);
            // }
        })



        this.setState({ isChecked: customer ? customer.consent : false });
        if (customer.prescription) {
            this.setState({ image: `${config.serviceUrl}/pres-images/${customer.prescription.filepath}`, imagename: customer.prescription.filepath })
        }

        console.log(customer.name, 999);


        this.setState({ isRegistered: (customer.name !== undefined && customer.name !== "") });
    }


    // getPrescription = () => {
    //     const customer = this.props.CustomerReducer.customer;
    //     let customer_id = customer._id || null
    //     let doctor_id = customer.prescribedDoctor._id || null
    //     //console.log(customer_id, doctor_id, customer, 4444444);
    //     if (customer_id && doctor_id) {
    //         CustomerService.getPrescription({ customer_id, doctor_id }).then(data => {
    //             //console.log(data, "getPrescription");
    //             if (data.success) {
    //                 let prescription = this.state.prescription
    //                 if (prescription) {
    //                     this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: prescription.filepath || "", selectedFile: true });
    //                 }
    //             }
    //         })
    //     }
    // }

    changeVal = async (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const registration = { ...this.state.registration };
        registration[name] = value;
        this.setState({ registration: registration });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.CustomerReducer.customer !== this.props.CustomerReducer.customer) {
            const customer = this.props.CustomerReducer.customer;
            this.setState({
                registration: customer ? this.customerRegModel(customer) : this.customerRegModel(),
                doctorName: customer.prescribedDoctor ? customer.prescribedDoctor.name : ""
            })
        }
    }


    changeAddr = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const address = { ...this.state.registration.address };
        address[name] = value;
        this.setState({ registration: { ...this.state.registration, address: { ...address } } });
    }
    // Function to fetch PDF from URL and convert to Base64
    fetchPdfAndConvertToBase64 = async (url) => {
        try {
            console.log(url, 88888999);
            const response = await fetch(url, { mode: 'no-cors' });
            const blob = await response.blob(); // Get the PDF as a Blob

            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ base64: reader.result }); // Base64 string
            };

            reader.readAsDataURL(blob); // Convert Blob to base64
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    next = (type = null) => {
        const addressLine1 = this.state.registration.address.addressLine1;
        const country = this.state.registration.address.country == "India" ? "India" : "India";
        const state = this.state.registration.address.state;
        const pin = this.state.registration.address.pin;
        const town = this.state.registration.address.town;
        const name = this.state.registration.name;
        const age = this.state.registration.age;
        const gender = this.state.registration.gender;
        const email = this.state.registration.email;
        const patientAddressLine1 = this.state.registration.address.patientAddressLine1;
        const patientPin = this.state.registration.address.patientPin;
        const patientState = this.state.registration.address.patientState;
        const patientTown = this.state.registration.address.patientTown;
        const drName = this.state.registration.drName;
        const drPin = this.state.registration.drPin;
        const drRegistrationNo = this.state.registration.drRegistrationNo;
        //console.log('dvdfbbgnghmnhjgfkl>>>>>>>>>>>', this.state)
        const { image } = this.state
        // console.log(image, 6666000)
        // return
        console.log(pin.toString().length, "pinnnn");
        if (email && email !== "") {
            if (!this.props.hoc.verifyEmail(email)) {
                this.props.hoc.customAlert('Please provide a valid email.', false)
                return false
            }
        }
        if (!name && name == "") {
            this.props.hoc.customAlert('Name is required.', false)
            return false
        } else if (!age && age == "") {
            this.props.hoc.customAlert('Age is required.', false)
            return false
        }
        else if (age < 18) this.props.hoc.customAlert("Age must be greater than or equal to 18", false)
        else if (!gender && gender == "") {
            this.props.hoc.customAlert('Gender is required.', false)
            return false
        } else if (!addressLine1 && addressLine1 == "") {
            this.props.hoc.customAlert('Address is required.', false)
            return false
        } else if (!pin && pin == "") {
            this.props.hoc.customAlert('Pincode is required.', false)
            return false
        }
        else if (pin && pin.toString().length < 6) {
            this.props.hoc.customAlert('Pincode must be 6 digits.', false)
            return false
        }
        else if (!country && country == "") {
            this.props.hoc.customAlert('Country is required.', false)
            return false
        }
        else if (!state && state == "") {
            this.props.hoc.customAlert('State is required.', false)
            return false
        }

        else if (!town && town == "") {
            this.props.hoc.customAlert('City/Town is required.', false)
            return false
        }

        else if (image == null || image == false) {
            this.props.hoc.customAlert("Please upload prescription", false)
        }


        //  else if (patientAddressLine1 == "") {
        //     this.props.hoc.customAlert('Patient Address Line 1 is required.', false)
        //     return false
        // } else if (patientPin == "") {
        //     this.props.hoc.customAlert('Pincode is required.', false)
        //     return false
        // } else if (patientPin && patientPin.toString().length < 6) {
        //     this.props.hoc.customAlert('Pincode must be 6 digits.', false)
        //     return false
        // } else if (patientState == "") {
        //     this.props.hoc.customAlert('State is required.', false)
        //     return false
        // } else if (patientTown == "") {
        //     this.props.hoc.customAlert('Town is required.', false)
        //     return false
        // }
        else if (drName == "") {
            this.props.hoc.customAlert('Doctor Name is required.', false)
            return false
        } else if (drRegistrationNo == "") {
            this.props.hoc.customAlert('Doctor Registration Number is required.', false)
            return false
        } else if (drPin == "") {
            this.props.hoc.customAlert('Doctor Pin is required.', false)
            return false
        } 
        else if (this.state.isChecked == false || this.state.isChecked == null) {

            this.props.hoc.customAlert('Please check consent checkbox.', false)
            return false
        }
        else {
            console.log(this.state.isChecked);
            // return
            //console.log('test 135465874>>>>>>>>>>>>>>>>>>>>>')
            const { registration, prescription, selectedFile } = this.state;

            // console.log(selectedFile,222);
            // registration.address.country = "India"
            const id = this.props.CustomerReducer.customer._id;
            let detailsCustomer = {
                customer_id: id,
                doctor_id: null,
            };
            let _registration = { ...registration, doctor_id: null }
            console.log(_registration, id, prescription, 898900);
            CustomerService.updateCustomer({ registration: _registration, id }).then(async res => {
                console.log(res, 5588888);
                if (res && res.data && res.data.success && res.data.data) {
                    console.log(res, 66666999999);
                    // return
                    let customer = res.data.data.customer
                    const prescriptionLength = customer[0].prescription ? customer[0].prescription.length : null;
                    const allPrescription = customer[0].prescription ? customer[0].prescription[prescriptionLength - 1] : null

                    let customerDetailsObjNew = {
                        _id: customer[0].customer._id,
                        customer_code: null,
                        country_code: "+91",
                        mobileno: customer[0].customer.mobileno,
                        age: customer[0].customer.age,
                        email: customer[0].customer.email,
                        gender: customer[0].customer.gender,
                        name: customer[0].customer.name,
                        drName: customer[0].doctors ? customer[0].doctors.drName : "",
                        drRegistrationNo: customer[0].doctors ? customer[0].doctors.drRegNo : "",
                        drPin: customer[0].doctors ? customer[0].doctors.pin : "",
                        address: {
                            _id: customer[0].address ? customer[0].address._id : "",
                            country: customer[0].address ? customer[0].address.country : "",
                            addressLine1: customer[0].address ? customer[0].address.addressLine1 : "",
                            addressLine2: customer[0].address ? customer[0].address.addressLine2 : "",
                            town: customer[0].address ? customer[0].address.town : "",
                            state: customer[0].address ? customer[0].address.state : "",
                            pin: customer[0].address ? customer[0].address.pin : "",
                        },
                        doctor_id: customer[0].doctors ? customer[0].doctors._id : "",
                        consent: customer[0].customer.consent ? customer[0].customer.consent : false,
                        prescription: allPrescription
                    };

                    console.log(customerDetailsObjNew, 6666);
                    this.setState({
                        registration: this.customerRegModel(customerDetailsObjNew)
                    })

                    this.setState({ isChecked: customer[0].customer.consent });
                    // if (customer["prescribedDoctor"] && Array.isArray(customer["prescribedDoctor"])) {
                    //     customer["prescribedDoctor"] = customer["prescribedDoctor"].length ? customer["prescribedDoctor"][0] : null
                    // }

                    console.log(customerDetailsObjNew, 6666);
                    let payload = new FormData()
                    payload.append("customer_id", customer[0].customer._id || null)
                    payload.append("doctor_id", customer[0].doctors ? customer[0].doctors._id : null)
                    payload.append("prescription", selectedFile)

                    CustomerService.uploadPrescription(payload).then(data => {
                        console.log(data.data.data.filepath, "data !!!!!!!!!!!!file pathh");
                        // this.getPrescription()
                        this.setState({ isLoading: false });
                        // this.setState({ fileUploadError: 'Prescription uploaded', color: "green", imagename: data.data.data.filepath });
                    }).catch(err => {
                        this.setState({ isLoading: false });
                        console.log(err, "err")
                    })
                    //console.log(customer, 87870987)
                    OtherService.getCustomerOut().then(async res => {
                        // //console.log(res, 7878);
                        if (res && res.data && res.data.success) {
                            //console.log(res, 123456);
                        }
                    }).catch(err => {
                        //console.log(err)
                    })
                    OtherService.getCustomerDetailsOut(detailsCustomer).then(res => {
                        // //console.log(res, 7878);
                        if (res && res.data && res.data.success) {
                            console.log(res, "response from customer out");
                        }
                    }).catch(err => {
                        //console.log(err)
                    })
                    this.props.setCustomer(customerDetailsObjNew);
                    // if (type === 'saveandproceed') {
                    // this.props.hoc.customAlert('Patient registered successfully.', true);
                    await this.props.hoc.customAlert('Patient registered successfully.', true, "", false, false)
                    this.setState({ isRegistered: true });
                    // this.props.navigate('/customer/place-order')
                    // } else {
                    // this.props.hoc.customAlert('Patient registered successfully.', true);
                    // }
                }
            }).catch(e => {
                //console.log(e)
            })

        }


    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.registration.address.state || null
                        console.log(_state, this.state.states, "jhgjhgjhg");
                        if (_state) {
                            let stateObj = null
                            for (let i = 0; i < this.state.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))
                }
                // }, 2000);
            })
        }
    }

    handleOrderHistory = () => {

        const customer = this.props.CustomerReducer.customer;

        if (customer) {

            let details = {
                customer_id: customer._id
            }
            try {
                CustomerService.getOrders(details).then(res => {
                    if (res && res.data && res.data.success && res.data.data) {


                        if (res.data.data.order.length > 0) {
                            // this.props.navigate('/customer/track-order')
                            // this.props.saveAllOrder(res.data.data.order);
                            this.props.navigate('/customer/orders')
                        } else {

                            this.props.hoc.customAlert('You dont have any order.', false);
                        }

                        console.log(res.data.data.order, "order details");

                    } else {

                        this.props.hoc.customAlert('You dont have any order.', false);
                    }

                })
            } catch (error) {
                console.log(error);
            }


        } else {

        }

        // this.props.navigate('/customer/orders');
    }

    handleConsentCheckbox = () => {

        const currentVal = !this.state.isChecked

        this.setState({ isChecked: currentVal });
        this.props.setConsentCheckbox(currentVal);

        const registration = { ...this.state.registration };
        registration['consent'] = currentVal;
        this.setState({ registration: registration });

    }

    handleNext = () => {
        this.props.navigate('/customer/place-order')
    }


    render = () => {
        const { fileUploadError, color, registration, imagename, countries, states, towns, isLoading, showTowns, image, isRegistered, numPages } = this.state;

        console.log(registration, 'registration');
        console.log(isRegistered, "isRegistered");
        console.log(imagename, "imagename");
        console.log(image, "image");

        const { name, address, age, email, gender, mobileno, drName, drRegistrationNo, drPin, prescription } = registration;


        const { country, addressLine1, addressLine2, pin, town, state, patientAddressLine1, patientAddressLine2, patientPin, patientCountry, patientState, patientTown } = address
        const { doctorName } = this.state;
        const { isChecked } = this.state;
        console.log(isChecked, 2555);
        const isCheckedConsent = this.props.CustomerReducer.consentValue;
        console.log(this.props.CustomerReducer.customer, 66666);

        console.log(prescription, 99999)
        console.log(isCheckedConsent, "isCheckedConsent", showTowns, town, state)
        //console.log(this.state, '>>>>>>>>>>>>>>>>>')
        return (
            <div>

                {/*myown-login-part-start-----*/}
                <section className="regstr-form">
                    <div className="container">
                        <CustomerHeader />

                        <div className="reg-frm-dta align-items-center">
                            <div className="reg-text-ft"><h3>Patient Information</h3></div>
                            <div className="know-more-text mb-0">
                                {/* <a href={config.knowMoreLink} className="kow-more-link text-underline" target="_blank" rel="noopener noreferrer">Know more</a> */}
                            </div>
                        </div>
                        <form className="mt-3 registration-form">
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Name <span className='requiredFld'> * </span></label>
                                    <input type="text" value={name} name="name" onChange={(event) => this.changeVal(event)} id className="form-control inpt_brdr_colr" placeholder />
                                </div>
                                <div className="col-md-1 mb-3 for-apper-pt">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Age <span className='requiredFld'> * </span></label>
                                    <input type="number" value={age} onInput={(e) => e.target.value = e.target.value.slice(0, 2)} onChange={this.changeVal} name='age' className="form-control inpt_brdr_colr" placeholder="Select age" />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label cls-gndr lvl-clr-hd">Gender <span className='requiredFld'> * </span></label>
                                    <select className="form-control inpt_brdr_colr" value={gender} onChange={(event) => this.changeVal(event)} name="gender">
                                        <option value="" disabled selected hidden>Please Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="others">Others</option>
                                    </select>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Email</label>
                                    <input type="text" value={email} name="email" onChange={(event) => this.changeVal(event)} id className="form-control inpt_brdr_colr" placeholder />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Mobile Number <span className='requiredFld'> * </span>
                                    </label>
                                    <input type="text" value={mobileno} disabled onChange={(event) => this.changeVal(event)} name="mobileno" id className="form-control inpt_brdr_colr" placeholder />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-10 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Address <span className='requiredFld'> * </span></label>
                                    <input type="text" value={addressLine1} onChange={(event) => this.changeAddr(event)} name='addressLine1' id className="form-control inpt_brdr_colr" placeholder />
                                </div>
                                <div className="col-md-2 mb-3 for-apper-pt">
                                    <label htmlFor className="form-label lvl-clr-hd">Pin <span className='requiredFld'> * </span></label>
                                    <input type='number' onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={pin} onChange={(event) => this.changeAddr(event)} name="pin" id className="form-control inpt_brdr_colr" placeholder />
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-md-10 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Address Line -2</label>
                                    <input type="text" value={patientAddressLine2} onChange={(event) => this.changeAddr(event)} name='patientAddressLine2' id className="form-control " placeholder />
                                </div> */}

                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Country <span className='requiredFld'> * </span></label>
                                    {
                                        countries.length &&
                                        <Dropdown
                                            items={countries}
                                            onSelect={(item) => {
                                                if (item) {
                                                    let _registration = this.state.registration
                                                    _registration.address["country"] = item.name
                                                    _registration.address["state"] = ""
                                                    _registration.address["town"] = ""
                                                    this.setState({ registration: _registration })
                                                    //console.log(item, "item");
                                                    this.populateStates(item.isoCode)
                                                }
                                            }}
                                            value={country}
                                            isDisabled={true}
                                        />
                                    }
                                    {/* <input type="text" readOnly value={"India"} onChange={(event) => this.changeAddr(event)} name="country" id className="form-control " placeholder /> */}
                                    {/* <input type="text" value={country} onChange={(event) => this.changeAddr(event)} name="country" id className="form-control " placeholder /> */}
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span></label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        let _registration = this.state.registration
                                                        _registration.address["state"] = item.name
                                                        _registration.address["town"] = ""
                                                        this.setState({ registration: _registration })
                                                        //console.log(item, "item");
                                                        this.populateTowns(item.countryCode, item.isoCode)
                                                    }
                                                }}
                                                value={state}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={state}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control " placeholder /> */}
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town <span className='requiredFld'> * </span></label>
                                    {/* <p>{town}  {towns.length}   {JSON.stringify(towns)}</p> */}
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        let _registration = this.state.registration
                                                        _registration.address["town"] = item.name
                                                        this.setState({ registration: _registration })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={town}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={town}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control " placeholder /> */}
                                </div>
                            </div>

                            <div className="align-items-center mt-5">
                                <div className="reg-text-ft">
                                    <h3>Doctor Details</h3>
                                    <p className='text-red'>(Note: Dr. details are available on the Prescription)</p>


                                </div>
                                <div className="know-more-text mb-0">

                                    {/* <a href={config.knowMoreLink} className="kow-more-link text-underline" target="_blank" rel="noopener noreferrer">Know more</a> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Dr. Name <span className='requiredFld'> * </span></label>
                                    <input type="text" value={drName} onChange={(event) => this.changeVal(event)} name='drName' id className="form-control inpt_brdr_colr" placeholder />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Dr. Registration Number<span className='requiredFld'> * </span></label>
                                    <input type="text" value={drRegistrationNo} onChange={(event) => this.changeVal(event)} name='drRegistrationNo' id className="form-control inpt_brdr_colr" placeholder />
                                </div>
                                <div className="col-md-2 mb-3 for-apper-pt">
                                    {/* <div className="my-locatn">
                                        <button type className="use-lctn-part" onclick>
                                            <LocationIcon className="ionicon" />
                                            Use My Location
                                        </button>
                                    </div> */}
                                    <label htmlFor className="form-label lvl-clr-hd">Pin <span className='requiredFld'> * </span></label>
                                    <input type='number' onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={drPin} onChange={(event) => this.changeVal(event)} name="drPin" id className="form-control inpt_brdr_colr" placeholder />
                                </div>
                            </div>


                            <div className=" mt-4 mb-4">
                                <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileChange}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}

                                />
                                <button type="button" className="upld-prscrbtn-pt" id onClick={this.handleButtonClick}>
                                    {this.state.imagename ? "Change Your Prescription" : "Upload Your Prescription"}
                                </button>
                                <p style={{ fontSize: 11, paddingTop: 10, textAlign: 'right', color: "#0a4f73", marginRight: 5 }}> Prescription needs to be uploaded in either jpeg, png and pdf format within a maximum limit of 25 MB <span className='requiredFld'> * </span></p>

                                {fileUploadError && <div style={{ color, textAlign: 'right', marginRight: 20 }}>{fileUploadError}</div>}
                                {
                                    image && imagename && imagename.split('.').pop().toLowerCase() !== 'pdf' ?
                                        <div className="col-md-4 col-upload-image">
                                            <div className="upload-image-box">
                                                <img
                                                    className="prescriptionimage"
                                                    src={image ? image : `${config.serviceUrl}/pres-images/${this.props.CustomerReducer.customer.prescription.filepath}`}
                                                    alt="Selected"
                                                />
                                            </div>
                                        </div> :
                                        (
                                            image ? (
                                                <div style={{ height: '400px' }}>
                                                    <PdfViewerWithPlugin pdfFile={image ? image : this.fetchPdfAndConvertToBase64(`${config.serviceUrl}/pres-images/${this.props.CustomerReducer.customer.prescription.filepath}`)} />
                                                </div>
                                            ) : (
                                                <p>No file selected</p>
                                            )
                                        )

                                }
                                {
                                    image &&
                                    <button type="button" style={{ width: '200px' }} className="btn btn-white form-control upload-btn clear-sec-btn me-2 mt-4" onClick={() => {
                                        this.setState({ image: null, imagename: "", fileUploadError: "" })
                                    }}>
                                        Clear Selection
                                    </button>
                                }

                                {this.state.imagename != "" && <p>Selected file: {this.state.imagename}</p>}
                            </div>

                            {/* <div className="reg-frm-dta align-items-center">
                                <div className="reg-text-ft"><h3>Product Delivery Address</h3></div>
                                <div className="know-more-text mb-0">
                                   
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Address Line -1 <span className='requiredFld'> * </span></label>
                                    <input type="text" value={addressLine1} onChange={(event) => this.changeAddr(event)} name='addressLine1' id className="form-control " placeholder />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Address Line -2</label>
                                    <input type="text" value={addressLine2} onChange={(event) => this.changeAddr(event)} name='addressLine2' id className="form-control " placeholder />
                                </div>
                                <div className="col-md-2 mb-3 for-apper-pt">
                                   
                                    <label htmlFor className="form-label lvl-clr-hd">Pin <span className='requiredFld'> * </span></label>
                                    <input type='number' onInput={(e) => e.target.value = e.target.value.slice(0, 6)} value={pin} onChange={(event) => this.changeAddr(event)} name="pin" id className="form-control " placeholder />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">Country <span className='requiredFld'> * </span></label>
                                    {
                                        countries.length &&
                                        <Dropdown
                                            items={countries}
                                            onSelect={(item) => {
                                                if (item) {
                                                    let _registration = this.state.registration
                                                    _registration.address["country"] = item.name
                                                    _registration.address["state"] = ""
                                                    _registration.address["town"] = ""
                                                    this.setState({ registration: _registration })
                                                    //console.log(item, "item");
                                                    this.populateStates(item.isoCode)
                                                }
                                            }}
                                            value={country}
                                            isDisabled={true}
                                        />
                                    }
                                   
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State <span className='requiredFld'> * </span></label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        let _registration = this.state.registration
                                                        _registration.address["state"] = item.name
                                                        _registration.address["town"] = ""
                                                        this.setState({ registration: _registration })
                                                        //console.log(item, "item");
                                                        this.populateTowns(item.countryCode, item.isoCode)
                                                    }
                                                }}
                                                value={state}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={state}
                                            />
                                    }
                                   
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town <span className='requiredFld'> * </span></label>
                                   
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        let _registration = this.state.registration
                                                        _registration.address["town"] = item.name
                                                        this.setState({ registration: _registration })
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={town}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={town}
                                            />
                                    }
                                  
                                </div>
                            </div>*/}
                            <div className="row mt-4">
                                <div className='col-md-1 checkboxstyle'>
                                    <input type="checkbox" className='form-label' onChange={this.handleConsentCheckbox} checked={isChecked} />
                                </div>
                                <div className='col-md-10 consenttext' >
                                    <p>I consent to the sharing of my personal data for the purpose of purchasing medicinal products. <Link to="/customer/concent" target='_blank' className="privacy-policy-text me-2">Click Here</Link></p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="end-bck-btn-pt">

                                    {!isRegistered ?
                                        <div className="">
                                            <button type="button" className="next-btn-crcl" id onClick={() => this.next('saveandproceed')}>
                                                Register
                                            </button>
                                        </div> : ""

                                    }
                                    <div className="">
                                        {/* <BackButton className="bck-btn-crcl" /> */}
                                        <button type="button" className="bck-btn-crcl" onClick={this.handleOrderHistory}>
                                            Order History
                                        </button>
                                    </div>

                                    {isRegistered ?
                                        <div className="">
                                            <button type="button" className="next-btn-crcl" id onClick={() => this.handleNext()}>
                                                Next
                                            </button>
                                        </div> : ""
                                    }
                                    {/* <div className="col-md-2">
                                        
                                        <button type="button" className="bck-btn-crcl" onClick={() => this.next('save')}>
                                            Save
                                        </button>
                                    </div> */}

                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                {/*myown-login-part-end-----------*/}
                <CustomerFooter />
            </div>
        )

    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setConsentCheckbox: (consentValue) => dispatch(CustomerAction.setConsentCheckbox(consentValue))
    }
}

const mapStateToProps = (state) => {
    return {
        // dispatching plain actions
        CustomerReducer: state.CustomerReducer,
        CommonReducer: state.CommonReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Registration)))
import React from "react";

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {

  }

  render = () => {
    return (
      <div className="contactus-div">
        {/* <p className="contactus-heading">Contact Us</p> */}
        {/* Table start */}
        <div className="contactdetails mt-2">
          <div className="row ">
            <div className="col-md-5">
              <p><img src={require("../../assets/images/whatsapp-icon1.png")} className="whatsappicon" alt="logo" />9892989315</p>
            </div>
            <div className="col-md-7">
              <p><i className="fa fa-envelope" aria-hidden="true"></i><span className="emailstyle">Nparekh@wockhardt.com</span></p>
            </div>
          </div>
        </div>
        {/* Table end */}
      </div>
    )
  }
}

export default ContactUs
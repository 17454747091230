import React from "react";
import LogoutIcon from "../../assets/Svg/LogoutICon";
import CommonAction from "../../redux/actions/Common.action";
import CustomerAction from "../../redux/actions/Customer.action";
import FeedbackAction from "../../redux/actions/Feedback.action";
import OrderAction from "../../redux/actions/Order.action";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import MrAction from "../../redux/actions/Mr.action";
export default function CustomerHeader(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customer = useSelector((state) => state.CustomerReducer.customer);
    const logout = () => {
        console.log(customer, "logout");
        dispatch(CommonAction.logout());
        dispatch(CustomerAction.clearState());
        dispatch(FeedbackAction.clearState());
        dispatch(OrderAction.clearState());
        dispatch(MrAction.logout());
        if (props.page && props.page == "tracking") {

            const url = "/login/";
            navigate(url)
        } else {
            // const id = customer && customer.prescribedDoctor && customer.prescribedDoctor._id;
            const url = "/customer/login/";
            navigate(url)
        }

    }
    return (
        <>
            <div className="row">
                <div className="w-100 logo-top-box mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-wrap headerlogo">
                            <a href="javascript:void(0);"><img src={require("../../assets/images/dgf-logo.png")} alt="logo" /></a>
                        </div>
                        <div>
                            <button type="submit" className="btn-logout" onClick={() => { logout() }}>
                                <LogoutIcon />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import OrderAction from "../redux/actions/Order.action";
import { store } from "../redux/index.store";
import Base from "./Base.service";
import { GENERATE_CHECKSUM, PAYMENT_REFUND } from "./Slugs";

class CCAvenueService extends Base {
    constructor(props) {
        super(props)
    }

    generateChecksum = (payload) => {
        console.log(payload, "payload generateChecksum");
        return new Promise(async resolve => {
            try {
                let response = await this.post(GENERATE_CHECKSUM, payload)
                console.log(response, "GENERATE_CHECKSUM");
                if (response.data && response.data.success) {
                    store.dispatch(OrderAction.savePaymentDetails(response.data.data))
                }
                resolve({ success: true })
            } catch (error) {
                console.log(error, "payload generateChecksum error");
                resolve({ success: false })
            }
        })
    }

    paymentRefund = (payload) => {
        console.log(payload, "payload paymentRefund");
        return new Promise(async resolve => {
            try {
                let response = await this.post(PAYMENT_REFUND, payload)
                console.log(response, "GENERATE_CHECKSUM");
                if (response.data && response.data.success) {
                    resolve({ success: true, data: response.data.data })
                }
                else {
                    resolve({ success: false, error: response })
                }
            } catch (error) {
                console.log(error, "payload generateChecksum error");
                resolve({ success: false, error })
            }
        })
    }
}

export default new CCAvenueService()
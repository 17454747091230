import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../../components/Reusable/WithRouter'
import CustomerHeader from '../../components/layout/CustomerHeader'
import config from '../../config/emrok.config'
import OrderPlacedModal from '../../components/Order/OrderPlacedModal'
import ThankYouModal from './ThankYouModal'
import CustomerFooter from '../../components/layout/CustomerFooter'
import Common from '../../hoc/Common.hoc'
import FeedbackService from '../../services/FeedbackService'
import ProductService from '../../services/ProductService'
import FeedbackRating from '../../components/FeedbackRating/FeedbackRating.component'
import ProductAction from '../../redux/actions/Product.action'


class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      message_1: "",
      message_2: "",
      customer: null,
      currentOrder: null,
      rating_1: 0,
      rating_2: 0,
      rating: 0,
      message: '',
      rating1: 0,
      message1: '',
      category: '',
      details: null, // Default rating
      messages: {},
      ratings: {}
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      products: props.products || [],
      customer: props.customer || null,
      currentOrder: props.currentOrder || null,
    }
  }

  componentDidMount = () => {
    this.getProducts()
  }

  getProducts = () => {


    const {customer, messages, ratings} = this.state;

    const stockist_id = customer!=""?customer.prescribedDoctor.stockist_id:"";
    console.log(customer,"customerData")
    let stockistPayload = {
        stockist_id:stockist_id
    }

    ProductService.getStockistCode(stockistPayload).then(result => {
      console.log(result,"result")

        if(result.data.success){
            console.log(result.data.data[0],"stockist details")

            let plant_id = result.data.data[0].plant_id
            ProductService.getProductsByPlantCode({plant_id}).then(res => {
                console.log(res,"response1233")
                if (res && res.data && res.data.success) {
                    if (res.data.data) {
                        // const products = res.data.data;
                        const products = [...new Map(res.data.data.map(item =>
                          [item["material"], item])).values()];
                        this.props.setProducts(products);
                        let messages = {}
                        let ratings = {}
                          for(let i = 0; i < products.length; i++) {
                            messages[`message_${i+1}`] = ""
                            ratings[`rating_${i+1}`] = ""
                          }
                          this.setState({messages, ratings})
                        // this.setState({ products: products, productQuantity: newProductQuantity })
                    }
    
                }
    
            }).catch(e => {
                console.log("e>>>>>>>>>>>>", e)
            })
        }
        // console.log(res,"stockist details")

    });

    // ProductService.getAllProducts().then(data => {
    //   console.log(data, "getProducts")
    // })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { message_1, message_2, customer, category, products, rating_1, rating_2 , currentOrder, messages, ratings } = this.state
    // const orders = this.props.OrderReducer;
    //  console.log(currentOrder,"order");
    // return
    // alert(category.length);
    if(category.length==0){
      this.props.hoc.customAlert("Please choose category", false, "Warning");
    }
    else {
      let products_length = products.length
      let ratings_count = false
      let messages_count = false
      for(let i = 0; i < products_length; i++) {
        if(ratings[`rating_${i+1}`]) {
          if(ratings[`rating_${i+1}`] !== "") {
            ratings_count = true
          }
        }
        if(messages[`message_${i+1}`]) {
          if(messages[`message_${i+1}`] !== "") {
            messages_count = true
          }
        }
      }
      if(!ratings_count) {
        this.props.hoc.customAlert("Please provide ratings.", false, "Warning");
      }
      else if(!messages_count) {
        this.props.hoc.customAlert("Please add comments.", false, "Warning");
      }
      else {
        let dataToSubmit = {
          customer_id: customer._id || null,
          order_id : currentOrder._id || null,
          feedbackType: category,
          products: []
        }

        for(let i = 0; i < products_length; i++) {
          dataToSubmit.products.push({
            product_id: products[i]._id,
              comment: messages[`message_${i+1}`],
              rating: ratings[`rating_${i+1}`]
          })
        }
    
        // console.log(dataToSubmit, "dataToSubmit")
        // return
        FeedbackService.createFeedback(dataToSubmit).then(data => {
          console.log(data.data, "feedback");
    
          // if (data.data.success && data.data.data.mrinfo !== '') {
          //   console.log(data.data.data.mrinfo[0], "okkkkk");
    
          //   this.setState({ mrinfo: data.data.data.mrinfo[0] });
          // }
    
    
    
          // this.setState({mrdetailsList:data.data.data.mrdetails});
          this.props.hoc.customAlertWithClick("Thank you for your feedback", true, "Thank you");
    
        }).catch(err => {
    
          this.props.hoc.customAlertWithClick("Thank you for your feedback", true, "Thank you");
    
          console.log(err, "err")
        })
      }
    }
    }
  // handelSubmit = () =>{

  //   this.props.hoc.customAlertWithClick("Thank you for your feedback",true,"Thank you");
  // }

  handleRatingChange = (e) => {
    this.setState({ rating: e.target.value });

  };
  handleRatingChange1 = (e) => {
    this.setState({ rating1: e.target.value });

  };

  handleInputChange = (e) => {
    this.setState({ category: e.target.value });
  };


  handleMessageChange = async (e, index) => {
    e.preventDefault()

    const messageData = e.target.value;
  
    if(messageData.length<=config.feedbackCommentCharLimit){
      let messages = await this.state.messages
      messages[`message_${index}`] = await messageData
      this.setState({ messages });
    }else{
      this.props.hoc.customAlert("You exced charecter limit", false, "Warning");
    }

    
  };

  handleMessageChange1 = (e) => {
    this.setState({ message1: e.target.value });
  };


  render = () => {
    const { rating, rating1, message1, category, products, message_1, message_2, rating_1, rating_2, ratings, messages } = this.state;
    console.log(ratings, messages, "ppppppppppp")
    return (
      <>
        <section className="feedback-form">
          <div className="container">
            <CustomerHeader />

            {/* Row End*/}
            <div className="row">
              <div className="page-title mt-4">
                <h2 className="lg-title text-capitalize mb-20">Feedback Form</h2>
              </div>
            </div>
            {/* Row End*/}
            <div className="row">
              <div className="col-md-12">
                <div className="feedback-select-box">
                  <form>
                    <div className="">
                      <label htmlFor="" className="form-label">
                        Select Category
                      </label>
                      <select
                        className="form-select feedBackSelect"
                        data-placeholder="Select an option"
                        name="selectedOption"
                        value={this.state.selectedOption}
                        onChange={this.handleInputChange}
                      >
                        <option value="" disabled selected hidden>Please Select</option>
                        <option value="complaint">Complaint</option>
                        <option value="order">Order</option>
                        <option value="query">Query</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* Row End*/}
          </div>
        </section>
        {/*feed-back-form-part-end---------*/}
        {/*write-review-part----------------*/}
        <section className="write-review-pt">
          <div className="container">
            <div className="review-part-ft mt-4">
              <h2>Write a Review</h2>
            </div>
            <div className="review-start-pt">
              {
                products && products.length && products.map((product, i) => {
                   return (messages.hasOwnProperty(`message_${i+1}`) && ratings.hasOwnProperty(`rating_${i+1}`)) ? (
                    <div className="row mt-3" key={i}>
                      <div className="col-3 col-md-2 col-lg-1 product-image-col">
                        <div className="product-dtls-image-content">
                          <div className="product-dtls-image-box">
                            <img src={`${config.serviceUrl}/images/${product.poduct_img !== "" ? product.poduct_img : "product_img/no-image.png"}?time=${(new Date()).getTime()}`} alt="Product-image" />
                          </div>
                        </div>
                      </div>
                      <div className="col-8 col-md-10 col-lg-11 emrok-pro-detls">
                        <h5>{product?.name}</h5>
                        <div id="half-stars-example">
                          <div className="rating-group">
                            <FeedbackRating
                              rating={ratings[`rating_${i+1}`]}
                              changeRating={async (rate) => {
                                let ratings = await this.state.ratings
                                ratings[`rating_${i+1}`] = await Math.round(rate)
                                this.setState({ratings})
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="textarea-msg-submt mt-4">
                        <textarea placeholder="Type your query..." defaultValue={""} value={messages[`message_${i+1}`]}
                          onChange={(e) => this.handleMessageChange(e, i + 1)} />
                      </div>
                    </div>
                  ) : null
                })
              }
              <div className="foot-wse-btn-pt mt-4">
                <div className="foot-info-fdbk">
                  <div className="know-more-text">
                    <a
                      href={config.knowMoreLink}
                      className="kow-more-link text-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Know more
                    </a>
                  </div>
                </div>
                <div className="footer-bottom-btn-group">
                  <button type="button" className="back-to-main me-3 mb-3" onClick={() => this.props.navigate('/customer/registration')}>
                    Back to Main
                  </button>
                  <button
                    type="submit"
                    //  data-bs-target="#orderPlaced"
                    //  data-bs-toggle="modal" 
                    className="new-sbmt-off" onClick={this.handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
              <CustomerFooter />
            </div>
          </div>
        </section>
        {/* <ThankYouModal dataTargetId={"orderPlaced"} navigateUrl={"/customer/order-information"}/> */}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    // setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer))
    setProducts: (products) => dispatch(ProductAction.setProducts(products))
  }
}

// const mapStateToProps = (state) => {
//   return {
//     OrderReducer: state.OrderReducer,
//     CustomerReducer: state.CustomerReducer
//   }
// }

const mapStateToProps = ({ CustomerReducer, OrderReducer, FeedbackReducer, ProductReducer }) => {
  const { customer } = CustomerReducer
  const { currentOrder } = OrderReducer
  const { details } = FeedbackReducer
  const { products } = ProductReducer
  console.log(products, 123);
  console.log(customer, 1234);
  console.log(currentOrder, 12345);
  return {
    // dispatching plain actions
    // OrderListReducer: state.OrderListReducer,
    customer,
    currentOrder,
    details,
    products
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(Feedback)))
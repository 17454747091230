import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../components/Reusable/WithRouter";
import config from "../../../config/emrok.config";
import Common from "../../../hoc/Common.hoc";
import axios from "axios";
import moment from "moment";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import "../../../assets/css/admin/style.css";
import "../../../assets/css/admin/responsive.css";
import DropdownDoctorRuntime from "../../../components/SearchableDropdown/DropdownDoctorRuntime.component";
import AdminService from "../../../services/Admin.service";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import StockistAction from "../../../redux/actions/Stockist.action";
import ProductService from "../../../services/ProductService";
import ProductAction from "../../../redux/actions/Product.action";
class ProductManagmentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          name: "Product Name",
        },
        {
          name: "Description",
        },
        {
          name: "Material ID",
        },
        {
          name: "Price",
        },
        {
          name: "Quantity",
        },
        {
          name: "Image",
        },
        {
          name: "Action",
        },
      ]
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      common_products: props.common_products || []
    }
  }

  componentDidMount() {
    this.getItems()
  }

  editProduct = async (item) => {
    console.log(item, "p edit");
    await this.props.setEditableProduct(item)
    this.props.navigate("/admin/product-managment/edit");
  };

  getItems = async () => {
    await this.props.setProduct(null)
    ProductService.getAllProduct().then(data => {
      console.log(data, 1234);
    })
  }

  addProduct = async (e) => {
    e.preventDefault()
    await this.props.setEditableProduct(null)
    this.props.navigate("/admin/product-managment/edit");
  }

  render = () => {
    const { common_products, columns } = this.state;
    console.log(this.state, "76767676");
    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  <div className="col-md-5 w-100 d-flex justify-content-between align-items-center">
                    <h5 className="mb-20">Product Management</h5>
                    <button
                      className="btn btn-primary me-4"
                      onClick={this.addProduct}
                    >
                      Add Product
                    </button>
                  </div>
                  <div className="row g-4">
                    <div className="table-responsive">
                      {common_products && common_products.length ? (
                        <CustomDatatable
                          pagination={true}
                          columns={columns}
                          data={common_products}
                          pageName="productManagenment"
                          editProduct={this.editProduct}
                        />
                      ) : (
                        <p className="norecord">
                          <strong>No Records Found</strong>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminFooter />
          </div>
        </div>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setEditableProduct: (product) => dispatch(ProductAction.setEditableProduct(product)),
    setProduct: (product) => dispatch(ProductAction.setProductsByMaterial(product))
    // setStockist: (stockistdetails) =>
    //   dispatch(StockistAction.setStockist(stockistdetails)),
  };
};

const mapStateToProps = ({ ProductReducer }) => {
  const { common_products } = ProductReducer
  return {
    common_products
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Common(ProductManagmentDashboard)));

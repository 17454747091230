import React, { Component } from "react";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import { withRouter } from "../../../components/Reusable/WithRouter";
import config from "../../../config/emrok.config";
import ProductService from "../../../services/ProductService";
import ProductAction from "../../../redux/actions/Product.action";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import "../../../assets/css/admin/style.css";
import "../../../assets/css/admin/responsive.css";

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      selectedFile: null,
      fileUploadError: '',
      imagename: '',
      isLoading: false,
      color: 'red',
      image: null
    }
    this.copyState = this.state
    this.fileInputRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    console.log(props, 6767);

    return {
      product: props.editable_product || {
        name: "",
        description: "",
        material: "",
        price: "",
        quantity: "",
        poduct_img: ""
      }
    }
  }

  componentDidMount = () => {
    console.log(this.props, 98898888)
  }

  componentWillUnmount = () => {
    // this.props.setEditableProduct(null)
  }

  handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log(this.state.product, 6666666, file);
    if (file) {
      const fileType = file.type.toLowerCase();
      const fileSizeMB = file.size / (1024 * 1024);
      // Validate file type
      if (fileType !== 'image/jpeg' && fileType !== 'image/jpg' && fileType !== 'image/png') {
        this.setState({
          isLoading: false,
          fileUploadError: 'Only JPG, JPEG, and PNG files are allowed.',
          color: "red"
        });
        return;
      }
      else if (fileSizeMB > 25) {
        this.setState({ isLoading: false });
        this.setState({ fileUploadError: 'File size must be less than 25MB.', color: "red" });

        return;
      }
      else if (!this.state.product.material || this.state.product.material == '') {
        this.setState({ isLoading: false });
        this.setState({ fileUploadError: 'Please enter product Id before choosing image.', color: "red" });

        return;
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        console.log(reader, 7777)
        this.setState({ image: reader.result })
      }
      reader.readAsDataURL(file)
      let newFile = await new File([file], this.state.product.material + '.png', { type: "image/png" })
      // let newFile = await new File([file], this.state.product._id + '.' + file.name.split('.')[file.name.split('.').length - 1], { type: file.type })
      this.setState({ selectedFile: newFile, fileUploadError: '', imagename: '' }, () => {
        this.handleUpload()
      });
    }
  }

  handleUpload = () => {
    console.log(this.state.isLoading, "loading status", this.state.selectedFile);
    // Handle the file upload logic here
    const { selectedFile } = this.state;
    console.log(selectedFile, 7878787);

    if (selectedFile) {
      this.setState({ isLoading: true });
      const fileType = selectedFile.type;
      const fileSizeMB = selectedFile.size / (1024 * 1024);
      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {

        this.setState({ isLoading: false });
        this.setState({ fileUploadError: 'Only JPG and PNG files are allowed.', color: "red" });

        return;
      }
      else if (fileSizeMB > 25) {
        this.setState({ isLoading: false });
        this.setState({ fileUploadError: 'File size must be less than 25MB.', color: "red" });

        return;
      }
    } else {
      this.setState({ fileUploadError: 'Please select a file first.' });
    }
  }

  submit = (e) => {
    e.preventDefault();

    const { selectedFile, product } = this.state;

    if (!this.state.product._id && !selectedFile) {
      this.setState({ fileUploadError: 'Please select a file first.' });
      return;
    }

    // Validate product fields
    if (isNaN(product.price) || product.price <= 0 || !/^\d+(\.\d{1,2})?$/.test(product.price)) {
      this.setState({ fileUploadError: 'Invalid price format. Price must be a number with up to two decimal places.' });
      return;
    }

    if (isNaN(+product.quantity) || +product.quantity < 0) {
      this.setState({ fileUploadError: 'Quantity must be a positive number.' });
      return;
    }

    console.log(this.state.product._id, selectedFile, 8898989898);
    let payload
    if (selectedFile) {
      payload = new FormData();
      payload.append("product", selectedFile);
      payload.append("name", product.name);
      payload.append("description", product.description);
      payload.append("quantity", product.quantity);
      payload.append("material", product.material);
      payload.append("price", product.price);
      if (this.state.product._id)
        payload.append("_id", product._id);
    }
    else {
      payload = {
        name: product.name,
        description: product.description,
        quantity: product.quantity,
        material: product.material,
        price: product.price,
      }
      if (this.state.product._id) {
        payload = { ...payload, _id: product._id }
      }
    }

    console.log(payload, "payload", product);
    ProductService.addProduct(payload).then(async data => {
      console.log(data, "jksdhfjksdh");

      this.setState({ isLoading: false });
      this.setState({ fileUploadError: 'Image uploaded', color: "green", imagename: `${this.state.product.material}.png` });
      this.props.navigate('/admin/product-managment/list');
    }).catch(err => {
      this.setState({ isLoading: false });
      console.log(err, "err");
    });

    // ProductService.changeImage(payload).then(async data => {
    //   this.setState({ isLoading: false });
    //   this.setState({ fileUploadError: 'Image uploaded', color: "green", imagename: data.data.data.filepath });
    //   this.props.navigate('/admin/product-managment/list');
    // }).catch(err => {
    //   this.setState({ isLoading: false });
    //   console.log(err, "err");
    // });
  }

  handleChange = (e, field) => {
    e.preventDefault();
    const { value } = e.target;

    if (field === "price") {
      // Validate price: only numbers and one dot allowed
      const regex = /^\d*\.?\d{0,2}$/;
      if (!regex.test(value)) {
        return; // Optionally show an error message
      }
      this.props.setEditableProduct({ ...this.state.product, [field]: value });
    } else if (field === "quantity") {
      // Validate quantity: must be numeric
      if (isNaN(value) || value.trim() === "") {
        return; // Optionally show an error message
      }
      this.props.setEditableProduct({ ...this.state.product, [field]: value });
    } else {
      this.props.setEditableProduct({ ...this.state.product, [field]: value });
    }
  }

  render = () => {
    const { product, color, fileUploadError, imagename, isLoading, selectedFile, image } = this.state;
    console.log(product, 898989898);

    return (
      <>
        <div className="layout-wrapper">
          <AdminHeader />
          <AdminSidebar />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row g-4">
                  <div className="col-md-6">
                    <h5 className="">Product Management</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="btn-home flotright">
                      <a href="javascript:void(0);" onClick={() => this.props.navigate('/admin/product-managment/list')}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        Back
                      </a>
                    </div>
                  </div>

                  <form className="registration-form">
                    <div className="row">
                      <div className="col-md-4 mb-2">
                        <label className="form-label mb-2 lvl-clr-hd">
                          Product Name <span className="requiredFld"> * </span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter product name"
                          className="form-control name-pt-ft rounded-4 inpt_brdr_colr"
                          value={product ? product.name : ""}
                          onChange={(e) => this.handleChange(e, "name")}
                          readOnly={false}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label className="form-label mb-2 lvl-clr-hd">
                          Product Description <span className="requiredFld"> * </span>
                        </label>
                        <input
                          type="text"
                          name="description"
                          placeholder="Enter product description"
                          className="form-control name-pt-ft rounded-4 inpt_brdr_colr"
                          value={product ? product.description : ""}
                          onChange={(e) => this.handleChange(e, "description")}
                          readOnly={false}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label className="form-label mb-2 lvl-clr-hd">
                          Product ID <span className="requiredFld"> * </span>
                        </label>
                        <input
                          type="text"
                          name="material"
                          placeholder="Enter product material"
                          className="form-control name-pt-ft rounded-4 inpt_brdr_colr"
                          value={product ? product.material : ""}
                          onChange={(e) => this.handleChange(e, "material")}
                          readOnly={false}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label className="form-label mb-2 lvl-clr-hd">
                          Product Price <span className="requiredFld"> * </span>
                        </label>
                        <input
                          type="number"
                          name="price"
                          placeholder="Enter product price"
                          className="form-control name-pt-ft rounded-4 inpt_brdr_colr"
                          value={product ? product.price : ""}
                          onChange={(e) => this.handleChange(e, "price")}
                          readOnly={false}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label className="form-label mb-2 lvl-clr-hd">
                          Product Quantity <span className="requiredFld"> * </span>
                        </label>
                        <input
                          type="text"
                          name="quantity"
                          placeholder="Enter product quantity"
                          className="form-control name-pt-ft rounded-4 inpt_brdr_colr"
                          value={product ? product.quantity : ""}
                          onChange={(e) => this.handleChange(e, "quantity")}
                          readOnly={false}
                        />
                      </div>
                      <div className="col-md-8 mb-3 mt-3">
                        <div className="d-flex flex-column">
                          <label className="form-label lvl-clr-hd mb-2">
                            Upload Product Image <span className="requiredFld"> * </span>
                          </label>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="me-4">
                                <input
                                  type="file"
                                  ref={this.fileInputRef}
                                  style={{ display: 'none' }}
                                  onChange={this.handleFileChange}
                                />
                                <button type="button" className="btn btn-white form-control upload-btn" onClick={() => {
                                  console.log(this.fileInputRef);
                                  this.fileInputRef.current.click()
                                }}>
                                  Please Upload Image
                                </button>
                                <p className="text-center" style={{ fontSize: 11, paddingTop: 10, textAlign: 'center', color: "#0a4f73", marginRight: 5 }}> Image needs to be uploaded in either jpeg or png format within a maximum limit of 25 MB </p>
                                {fileUploadError && <div style={{ color, textAlign: 'center', marginRight: 0 }}>{fileUploadError}</div>}
                                {this.state.imagename != "" && <p>Selected file: {this.state.imagename}</p>}
                              </div>
                            </div>
                            {
                              (image || product && product.poduct_img) &&
                              <div className="col-md-4 col-upload-image">
                                <div className="upload-image-box">
                                  <img
                                    className="prescriptionimage"
                                    src={image || `${config.serviceUrl}/images/${product.poduct_img !== "" ? product.poduct_img : "product_img/no-image.png"}?time=${(new Date()).getTime()}`}
                                    alt="Selected"
                                  />
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-4">
                        <div className="end-bck-btn-pt">
                          <div>
                            <button type="button" className="next-btn-crcl" onClick={this.submit}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        {
                          image &&
                          <button type="button" className="btn btn-white form-control upload-btn clear-sec-btn me-2" onClick={() => {
                            this.setState({ image: null })
                          }}>
                            Clear Selection
                          </button>
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <AdminFooter />
        </div>
      </>
    );
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEditableProduct: (product) => dispatch(ProductAction.setEditableProduct(product))
  };
};

const mapStateToProps = ({ ProductReducer }) => {
  const { editable_product } = ProductReducer
  return {
    editable_product
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Common(ProductEdit)));
